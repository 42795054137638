.row {
  margin: 0; }

.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12 {
  padding: 0; }

*, body, input {
  font-family: "futura-pt", Arial, Helvetica, sans-serif; }

h1, .header-one {
  font-family: "futura-pt-bold", sans-serif;
  font-size: 50px;
  line-height: normal;
  color: #000000; }

h2, .header-two {
  font-family: "futura-pt";
  font-size: 42px;
  font-weight: 500;
  line-height: normal;
  color: #000000; }

h3, .header-three {
  font-family: "futura-pt";
  font-size: 35px;
  font-weight: 400;
  line-height: normal;
  color: #000000; }

h4, .header-four {
  font-family: "futura-pt";
  font-size: 27px;
  font-weight: 400;
  line-height: normal;
  color: #000000; }

h5, .header-five {
  font-family: "futura-pt-bold", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
  color: #000000; }

p, .body-1 {
  font-family: "futura-pt";
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  color: #000000; }

.body-2 {
  font-family: "futura-pt";
  font-size: 17px;
  font-weight: 500;
  line-height: 23px;
  color: #000000; }

.caption {
  font-family: "futura-pt";
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: .6px;
  text-transform: uppercase;
  color: #666666; }

.button-text {
  font-family: "futura-pt";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #000000; }

.pt-button:not([class*="pt-intent-"]) {
  font-family: "futura-pt";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #000000;
  padding: 15px 25px;
  box-shadow: none;
  border-radius: 50px;
  background-color: #F0F3F4;
  background-image: none;
  outline: none; }
  .pt-button:not([class*="pt-intent-"]):hover, .pt-button:not([class*="pt-intent-"]):active, .pt-button:not([class*="pt-intent-"]):focus {
    box-shadow: none;
    background-color: #e7ecee; }

.pt-button[class*="pt-intent-"] {
  font-family: "futura-pt";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: .5px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 15px 25px;
  box-shadow: none;
  border-radius: 50px;
  background-color: #00BFFF;
  background-image: none;
  outline: none; }
  .pt-button[class*="pt-intent-"]:hover, .pt-button[class*="pt-intent-"]:active, .pt-button[class*="pt-intent-"]:focus {
    box-shadow: none;
    background-color: #00b4f0; }

.pt-button.pt-intent-success {
  background-color: #09D657; }
  .pt-button.pt-intent-success:hover, .pt-button.pt-intent-success:active, .pt-button.pt-intent-success:focus {
    background-color: #08c751; }

.pt-button.pt-intent-primary {
  background-color: #00BFFF; }
  .pt-button.pt-intent-primary:hover, .pt-button.pt-intent-primary:active, .pt-button.pt-intent-primary:focus {
    background-color: #00b4f0; }
  .pt-button.pt-intent-primary.inverted {
    background-color: #FFFFFF;
    color: #00BFFF; }
    .pt-button.pt-intent-primary.inverted:hover, .pt-button.pt-intent-primary.inverted:active, .pt-button.pt-intent-primary.inverted:focus {
      background-color: #F0F3F4; }

.pt-button[class*="pt-icon-"] {
  border-radius: 4px; }

.pt-button.menu-button {
  border-radius: 4px;
  padding: 10px 15px;
  background-color: #FFFFFF;
  color: #666666;
  border: #F0F3F4 1px solid;
  width: 100%; }
  .pt-button.menu-button:hover {
    background-color: #F0F3F4;
    color: #666666;
    border: #99e5ff 1px solid; }

.pt-button.button-chip {
  margin: 5px 5px;
  padding: 5px 10px; }

.pt-dialog-close-button {
  outline: none; }

.chips {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  margin: 5px 0 10px; }

.nav-link:hover {
  color: inherit;
  text-decoration: none; }

@media only screen and (min-width: 600px) {
  .pt-button.menu-button {
    width: initial; }
  .chips {
    margin: -10px 0 10px; } }

.pt-non-ideal-state {
  margin-top: 10%;
  height: inherit; }

.pt-non-ideal-state > :not(:last-child) {
  margin-bottom: 10px; }

.pt-non-ideal-state-title {
  font-family: "futura-pt";
  font-size: 27px;
  font-weight: 400;
  line-height: normal;
  color: #000000; }

.pt-non-ideal-state-description {
  font-family: "futura-pt";
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  color: #000000; }

.pt-non-ideal-state-action {
  margin-top: 15px; }

.list {
  padding: 15px 0; }
  .list p {
    margin: 0; }

.list-item {
  padding: 15px 30px; }
  .list-item:hover {
    background-color: #F0F3F4;
    cursor: pointer; }
  .list-item .body-2 {
    margin-bottom: 5px;
    font-size: 19px; }
  .list-item .avatar {
    margin-right: 15px; }
  .list-item.nav-link-list-item {
    padding: 0 30px 0 0; }
    .list-item.nav-link-list-item .nav-link {
      padding: 15px 0 15px 30px; }
  .list-item .list-item-checkbox {
    margin: 0; }

.list-item-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 0; }

.label {
  font-family: "futura-pt";
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: .6px;
  text-transform: uppercase;
  color: #000000;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #FFE300; }
  .label.green {
    font-family: "futura-pt";
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: .6px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #09D657; }
  .label.red {
    font-family: "futura-pt";
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: .6px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #FF0707; }
  .label.orange {
    font-family: "futura-pt";
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: .6px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-color: #FF7D00; }

.list-item-popover .pt-button {
  font-family: "futura-pt";
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  color: #666666;
  padding: 0;
  text-transform: none;
  background-color: inherit; }
  .list-item-popover .pt-button:hover {
    color: #000000;
    background-color: inherit; }
  .list-item-popover .pt-button .pt-icon-standard {
    color: #666666; }
    .list-item-popover .pt-button .pt-icon-standard:hover {
      color: #000000; }

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%; }

.copy-text-button {
  margin-right: 5px; }

a.list-item {
  text-decoration: none; }

.pt-popover {
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.12); }

.pt-popover-arrow {
  display: none; }

.pt-menu {
  padding: 5px 0; }
  .pt-menu li a {
    font-family: "futura-pt";
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    color: #000000;
    padding: 15px; }
    .pt-menu li a:hover {
      background-color: #F0F3F4; }
    .pt-menu li a:focus {
      outline: none;
      background-color: #e7ecee; }

.pt-dialog {
  background-color: #FFFFFF; }

.pt-dialog-header {
  padding: 30px 20px 30px 30px;
  box-shadow: none; }
  .pt-dialog-header h5 {
    font-family: "futura-pt";
    font-size: 27px;
    font-weight: 400;
    line-height: normal;
    color: #000000; }

.pt-dialog-body {
  margin: 15px 30px; }

.pt-dialog-footer {
  margin: 15px 30px 0; }

.pt-input {
  font-family: "futura-pt";
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  color: #666666;
  height: auto;
  margin-bottom: 15px;
  padding: 9px 15px;
  border: 1px solid #F0F3F4;
  line-height: normal;
  background-color: #FFFFFF;
  box-shadow: none; }
  .pt-input:hover {
    border-color: #99e5ff;
    transition: border-color .2s; }
  .pt-input:focus {
    box-shadow: none;
    border-color: #00BFFF;
    transition: border-color .2s; }

.pt-select {
  margin-bottom: 15px; }
  .pt-select select {
    font-family: "futura-pt";
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    color: #666666;
    height: auto;
    padding: 9px 25px 9px 15px;
    border: 1px solid #F0F3F4;
    box-shadow: none;
    background-color: #FFFFFF;
    background-image: none; }
    .pt-select select:hover {
      background-color: #F0F3F4;
      box-shadow: none;
      border-color: #99e5ff;
      transition: border-color .2s; }
    .pt-select select:focus {
      box-shadow: none;
      outline: none;
      border-color: #00BFFF;
      transition: border-color .2s; }
  .pt-select::after {
    line-height: 43px; }

.input-row .pt-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
  .input-row .pt-input:last-of-type {
    margin-left: -1px;
    border-radius: 0 3px 3px 0; }

.pt-label .pt-input {
  margin-top: 5px; }

.hide, .hide-up-to-tablet-portrait, .hide-up-to-tablet-landscape, .hide-up-to-desktop, .hide-up-to-big-desktop {
  display: none; }

.show, .show-up-to-tablet-portrait, .show-up-to-tablet-landscape, .show-up-to-desktop, .show-up-to-big-desktop {
  display: inline; }

@media only screen and (min-width: 600px) {
  .hide-up-to-tablet-portrait {
    display: inline; }
  .show-up-to-tablet-portrait {
    display: none; } }

@media only screen and (min-width: 900px) {
  .hide-up-to-tablet-landscape {
    display: inline; }
  .show-up-to-tablet-landscape {
    display: none; } }

@media only screen and (min-width: 1200px) {
  .hide-up-to-desktop {
    display: inline; }
  .show-up-to-desktop {
    display: none; } }

@media only screen and (min-width: 1800px) {
  .hide-up-to-big-desktop {
    display: inline; }
  .show-up-to-big-desktop {
    display: none; } }

.pt-control input:checked ~ .pt-control-indicator, .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator {
  background-color: #00BFFF; }

.pt-control:hover input:checked ~ .pt-control-indicator, .pt-control:hover .pt-control.pt-checkbox input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox .pt-control:hover input:indeterminate ~ .pt-control-indicator, .pt-control.pt-checkbox:hover input:indeterminate ~ .pt-control-indicator {
  background-color: #00ace6; }

.pt-menu .pt-checkbox {
  margin: 15px; }

.form {
  margin: 30px 0 15px 30px;
  max-width: 600px; }
  .form .description {
    margin-bottom: 30px; }
  .form .pt-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px; }
  .form .pt-text-muted {
    margin-top: 4px; }

.form-label {
  align-items: start;
  justify-content: space-between; }

.cards-view {
  margin-bottom: 30px; }
  .cards-view h5 {
    padding: 30px 30px 0; }

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 15px; }

.card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.card-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start; }
  .card-content .header-one {
    margin: 0; }
  .card-content .card-text {
    flex: 1;
    margin-left: 15px; }
    .card-content .card-text p {
      margin: 0; }
  .card-content .request {
    margin-top: 15px; }
    .card-content .request .body-1 {
      margin: 0; }
  .card-content .courses {
    width: 100%;
    margin-top: 15px; }
  .card-content .course {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .card-content .course .pt-icon {
      flex-grow: 0; }

.card-wrapper {
  width: 100%;
  padding: 15px;
  overflow: hidden; }
  .card-wrapper-stats:last-of-type {
    flex: 1; }
  .card-wrapper .pt-card {
    width: 100%;
    padding: 0;
    overflow: hidden;
    border: 1px solid #C3CFD4;
    border-radius: 8px;
    box-shadow: none; }
    .card-wrapper .pt-card .pt-button.card-button {
      width: 50%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 0;
      border-top: 1px solid #C3CFD4;
      background-color: #FFFFFF; }
      .card-wrapper .pt-card .pt-button.card-button.decline {
        color: #E30000; }
      .card-wrapper .pt-card .pt-button.card-button.accept {
        color: #09D657; }
      .card-wrapper .pt-card .pt-button.card-button:first-of-type {
        left: -1px;
        box-shadow: inset -1px 0 0 0 #c3cfd4; }

.badge {
  font-family: "futura-pt";
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: .6px;
  text-transform: uppercase;
  color: #666666; }

@media only screen and (min-width: 600px) {
  .card-wrapper {
    width: 50%; } }

@media only screen and (min-width: 1200px) {
  .card-wrapper {
    width: 33.3%; } }

@media only screen and (min-width: 1800px) {
  .card-wrapper {
    width: 20%; } }

.header-has-label {
  display: flex;
  align-items: center; }
  .header-has-label .label-wrapper {
    margin-left: 10px;
    flex-grow: 0; }
    .header-has-label .label-wrapper .label {
      display: flex;
      align-items: center; }

.charts {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -15px; }

.graph {
  position: relative;
  width: 100%; }
  .graph .graph-inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 30px 30px 0; }
    .graph .graph-inner-wrapper-chart-js {
      width: 100%;
      min-height: 400px;
      max-height: 400px; }
  .graph .graph-title {
    text-align: left;
    padding: 0; }
    .graph .graph-title span {
      display: flex;
      justify-content: center;
      align-items: center; }
    .graph .graph-title .label {
      margin-left: 10px; }
  .graph .graph-explain-content {
    text-align: left;
    max-width: 560px;
    color: #666666; }
  .graph .graph-title-icon {
    margin-left: 10px; }

.divider {
  float: left;
  height: 1px;
  width: 100%;
  margin: 15px 0;
  background-color: #F0F3F4; }

.group-graphs {
  width: 100%; }
  .group-graphs .heatmap {
    padding-bottom: 20px; }

.percentage-bar {
  width: 100%; }
  .percentage-bar:first-of-type {
    padding-bottom: 20px; }
  .percentage-bar-subheaders {
    width: 100%; }
  .percentage-bar-item {
    width: 100%;
    height: 30px;
    margin: 5px 0;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start; }
  .percentage-bar-content {
    width: 100%;
    margin: 0;
    padding: 0 15px;
    color: #000000; }
  .percentage-bar-subheader {
    height: 30px;
    padding: 0 15px;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center; }

.percentage, .percentage-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  border-radius: 4px;
  background-color: #00BFFF;
  z-index: -1; }
  .percentage-background {
    width: 100%;
    background-color: #e6f9ff;
    z-index: -2; }

.heatmap {
  width: 100%; }
  .heatmap-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin: 5px 2.5px;
    border-radius: 4px;
    color: #000000; }
    .heatmap-content.insufficient {
      background-color: #F9AF00; }
  .heatmap-item {
    max-width: 240px; }
  .heatmap-content-tooltip {
    display: flex; }
  .heatmap-content-subheader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 0;
    height: 30px;
    margin: 5px 2.5px;
    padding-right: 15px; }
  .heatmap-subheader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: none;
    background-color: initial;
    margin: 0; }

.card-chart {
  width: calc(100% + 32px);
  margin: 0 -15px -15px; }
  .card-chart .chartjs-render-monitor {
    border-radius: 0 0 9px 7px; }

@media only screen and (min-width: 900px) {
  .graph .graph-inner-wrapper {
    padding: 25px;
    border: 1px solid #C3CFD4;
    border-radius: 8px; }
    .graph .graph-inner-wrapper-chart-js {
      height: 500px;
      max-height: 500px; }
  .percentage-bar:first-of-type {
    padding: 0;
    padding-right: 20px; }
  .percentage-bar:last-of-type {
    padding-left: 20px; }
  .group-graphs .heatmap {
    padding: 0;
    padding-left: 20px; } }

@media only screen and (min-width: 1200px) {
  .graph .graph-inner-wrapper-chart-js {
    height: 600px;
    max-height: 600px; } }

@media only screen and (min-width: 1800px) {
  .heatmap {
    width: 50%;
    padding-right: 20px; } }

@keyframes menuOpen {
  0% {
    transform: translate3d(-240px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes menuClose {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(-240px, 0, 0); } }

@keyframes menuOpenRTL {
  0% {
    transform: translate3d(240px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes menuCloseLTR {
  0% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(240px, 0, 0); } }

#sidenav-wrapper {
  position: absolute;
  height: 100vh;
  max-width: 240px;
  z-index: 20; }

.sidenav {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 240px;
  background-color: #022F40;
  overflow-y: scroll;
  transform: translate3d(-240px, 0, 0); }
  .sidenav.open {
    animation: menuOpen;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-timing-function: ease-out; }
  .sidenav.close {
    animation: menuClose;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-timing-function: ease-out; }

@media only screen and (min-width: 900px) {
  .sidenav.close {
    animation: none !important; } }

.sidenav-header {
  height: 118px;
  min-height: 118px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5); }
  .sidenav-header h5 {
    margin: 0;
    padding: 0 30px;
    flex-grow: 1;
    color: #FFFFFF; }
  .sidenav-header .pt-icon-standard {
    padding-right: 15px;
    color: #FFFFFF; }

.sidenav-items {
  display: inline;
  margin: 15px 0 0;
  padding: 0; }

.sidenav-item {
  text-decoration: none; }
  .sidenav-item:hover {
    text-decoration: none; }
  .sidenav-item .body-2 {
    padding: 10px 30px;
    color: #FFFFFF;
    cursor: pointer; }
    .sidenav-item .body-2:hover {
      color: #00BFFF;
      background-color: rgba(255, 255, 255, 0.1);
      transition: color .2s, background-color .2s; }
    .sidenav-item .body-2:active {
      background-color: rgba(255, 255, 255, 0.15); }
  .sidenav-item.active .body-2 {
    background-color: rgba(255, 255, 255, 0.15); }
  .sidenav-item.savvy .body-2 {
    margin-bottom: 15px;
    padding: 12px 30px;
    color: #022F40;
    background-color: #FFE300; }

.logout {
  margin: 15px 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.sidenav-logo {
  margin: 30px 0 30px 30px;
  flex: 1;
  display: flex;
  align-items: flex-end; }

@media only screen and (min-width: 1200px) {
  #sidenav-wrapper {
    position: relative; }
    #sidenav-wrapper .sidenav {
      transform: translate3d(0, 0, 0); } }

.authenticate {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .authenticate .logo {
    position: absolute;
    top: 15px;
    margin: 0 auto; }

.authentication-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .authentication-form input {
    margin-top: 15px; }
  .authentication-form button {
    margin-top: 25px; }

.authentication-description {
  max-width: 320px;
  text-align: center; }

span.pt-icon-standard.authentication-succes {
  margin-bottom: 15px;
  font-size: 72px;
  color: #09D657; }

.authentication-input-group {
  display: flex;
  flex-direction: column;
  margin: 25px 0 15px; }
  .authentication-input-group .pt-input {
    margin: 0;
    border-radius: 4px 4px 0 0; }
    .authentication-input-group .pt-input:last-of-type {
      margin-top: -1px;
      border-radius: 0 0 4px 4px; }

.schoolcode-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

.schoolcode-content-wrapper {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr; }

.schoolcode-content {
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 10;
  margin-top: 35%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; }
  .schoolcode-content p {
    max-width: 320px;
    text-align: center; }
  .schoolcode-content * {
    background-color: rgba(255, 255, 255, 0.95); }

.generated-schoolcode-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.generated-schoolcode {
  margin: 15px 0 0;
  padding: 5px 15px;
  border: 1px solid #666666;
  border-radius: 4px; }

.schoolcode-help {
  margin-left: 10px;
  color: #00BFFF; }

.schoolcode-explain {
  padding: 15px; }
  .schoolcode-explain p {
    max-width: 320px; }

.sign-up-container {
  display: none;
  position: relative;
  grid-row-start: 1;
  grid-column-start: 1; }

.sign-up-bubble {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.32);
  margin: 10px;
  border: 3px solid #fff;
  transition: box-shadow, transform;
  transform: translate3d(0px, 0px, 0px);
  animation: float 6s ease-in-out 1.5s infinite, ripple 1.5s cubic-bezier(0.12, 0.46, 0.3, 0.96); }
  .sign-up-bubble-name {
    font-family: "futura-pt";
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: .6px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0;
    background-image: radial-gradient(50% 60%, #004054 0%, #041C23 100%);
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    overflow: hidden;
    border-radius: 54px;
    width: 54px;
    height: 54px; }
  .sign-up-bubble:nth-child(2n+1) {
    transform: translate3d(0px, 0px, 0px);
    animation: float-1 6s ease-in-out 1s infinite, ripple 1.5s cubic-bezier(0.12, 0.46, 0.3, 0.96); }
  .sign-up-bubble:nth-child(2n+2) {
    transform: translate3d(0px, 0px, 0px);
    animation: float-2 7s ease-in-out 1s infinite, ripple 1.5s cubic-bezier(0.12, 0.46, 0.3, 0.96); }
  .sign-up-bubble:nth-child(2n+3) {
    transform: translate3d(0px, 0px, 0px);
    animation: float-3 6s ease-in-out 1s infinite, ripple 1.5s cubic-bezier(0.12, 0.46, 0.3, 0.96); }
  .sign-up-bubble:nth-child(2n+4) {
    transform: translate3d(0px, 0px, 0px);
    animation: float-4 6s ease-in-out 1s infinite, ripple 1.5s cubic-bezier(0.12, 0.46, 0.3, 0.96); }
  .sign-up-bubble:nth-child(2n+5) {
    transform: translate3d(0px, 0px, 0px);
    animation: float-5 7s ease-in-out 1s infinite, ripple 1.5s cubic-bezier(0.12, 0.46, 0.3, 0.96); }

@keyframes float-1 {
  0% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(0px, -20px, 0px); }
  100% {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes float-2 {
  0% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(-10px, -5px, 0px); }
  100% {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes float-3 {
  0% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(10px, 30px, 0px); }
  100% {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes float-4 {
  0% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(20px, -5px, 0px); }
  100% {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes float-5 {
  0% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(-15px, 17px, 0px); }
  100% {
    transform: translate3d(0px, 0px, 0px); } }

@keyframes ripple {
  0% {
    transform: scale3d(0, 0, 1);
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(2, 47, 64, 0), 0 0 0 0 rgba(2, 47, 64, 0); }
  15% {
    transform: scale3d(1.2, 1.2, 1); }
  20% {
    transform: scale3d(1, 1, 1);
    box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(2, 47, 64, 0), 0 0 0 0 rgba(2, 47, 64, 0); }
  25% {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.32), 0 0 0 0 rgba(2, 47, 64, 0.1), 0 0 0 1em rgba(2, 47, 64, 0.1); }
  100% {
    transform: scale3d(1, 1, 1);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.24), 0 0 0 2em rgba(2, 47, 64, 0), 0 0 0 5em rgba(2, 47, 64, 0); } }

@media only screen and (min-width: 600px) {
  .schoolcode-content {
    margin-top: 25%; } }

@media only screen and (min-width: 900px) {
  .sign-up-container {
    display: inline; }
  .schoolcode-content {
    margin-top: 15%; } }

@media only screen and (min-width: 1200px) {
  .schoolcode-content {
    margin-top: 10%; } }

.error-page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .error-page .logo {
    position: absolute;
    top: 15px;
    margin: 0 auto; }

.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .error-wrapper button {
    margin-top: 25px; }

.error-description {
  max-width: 320px;
  text-align: center; }

.filter-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 240px;
  z-index: 11;
  background-color: #FFFFFF;
  box-shadow: -4px 0 8px 0 rgba(0, 0, 0, 0.12);
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none; }
  .filter-menu-header {
    width: 100%;
    height: 118px;
    min-height: 118px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-shadow: inset 0 -1px 0 #C3CFD4; }
    .filter-menu-header h5 {
      margin: 0; }
  .filter-menu-content {
    width: 100%;
    padding: 15px 30px; }
  .filter-menu-list {
    width: 100%; }
    .filter-menu-list .body-2 {
      margin-bottom: 10px; }
    .filter-menu-list .pt-select {
      margin-bottom: 10px; }
  .filter-menu-reset {
    margin-top: 10px; }
  .filter-menu.open {
    animation: menuOpenRTL;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-timing-function: ease-out; }
  .filter-menu.close {
    animation: menuCloseLTR;
    animation-duration: .3s;
    animation-fill-mode: both;
    animation-timing-function: ease-out; }

.filter-menu::-webkit-scrollbar {
  display: none; }

.pt-navbar {
  height: calc(118px / 2);
  padding: 0 15px;
  box-shadow: inset 0 -1px 0 #C3CFD4;
  position: sticky;
  top: 0; }
  .pt-navbar .pt-input {
    font-family: "futura-pt";
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    color: #666666;
    height: auto;
    margin: 0 15px;
    padding: 9px 15px;
    border: 0;
    line-height: normal;
    background-color: #F0F3F4;
    box-shadow: none; }
  .pt-navbar--button-group {
    display: flex;
    flex-direction: row; }
    .pt-navbar--button-group .pt-popover-target, .pt-navbar--button-group .pt-button {
      margin-left: 15px; }
    .pt-navbar--button-group .pt-popover-target:first-of-type, .pt-navbar--button-group .pt-button:first-of-type {
      margin: 0; }

.pt-navbar-heading {
  font-family: "futura-pt";
  font-size: 35px;
  font-weight: 400;
  line-height: normal;
  color: #000000;
  margin: 0 15px;
  width: 100%; }
  .pt-navbar-heading.pt-fill {
    flex: 2; }

@media only screen and (max-width: 600px) {
  .pt-navbar-heading {
    font-size: 25px; } }

@media only screen and (min-width: 600px) {
  .pt-navbar {
    height: 118px; }
  .pt-navbar-heading {
    width: inherit; } }

.bulk-import {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15% 25px 25px; }
  .bulk-import .filepond--wrapper {
    min-width: 300px;
    width: 100%;
    margin: 25px auto 15px; }
  .bulk-import .filepond--drop-label {
    font-family: "futura-pt";
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    color: #000000;
    border-radius: 12px;
    background-color: #F0F3F4; }

.map-bulk-import {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15% 25px 25px; }

.bulk-mapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 25px 0 35px; }
  .bulk-mapper .body-2 {
    margin: 0;
    padding: 10px;
    text-align: center; }
  .bulk-mapper .body-1 {
    margin: 0; }

.map-headers {
  align-items: center;
  justify-content: center; }
  .map-headers .map-header {
    padding: 10px; }
  .map-headers .pt-select {
    margin: 0; }

.explain-mapping {
  max-width: 450px;
  text-align: center; }

.imported-header {
  background-color: #F0F3F4;
  padding: 10px 15px;
  border-radius: 3px; }

@media only screen and (min-width: 900px) {
  .bulk-import, .map-bulk-import {
    width: 50%;
    margin: 15% auto 25px; } }
